import * as React from 'react';
import Map, { Marker, ViewState } from 'react-map-gl';
import "mapbox-gl/dist/mapbox-gl.css";
import {useQuery} from 'react-query';
import axios from 'axios';

type Account = {
  latitude: number
  longitude: number
  icon: string
  name: string
  description: string
  instagram: string
  youtube: string
  charity: string
}

function App() {
  const [viewState, setViewState] = React.useState<Partial<ViewState>|null>()
  const req = useQuery<Account>('Account', async () => {
    if (window.location.origin === 'https://tracklive.me' && window.location.pathname === '/') {
      return {
        latitude: -33.4109589,
        longitude: 150.3035598,        
      } as Account
    }
    let url = `https://us-central1-gps-tracker-ccad4.cloudfunctions.net/app/status/now` // look up is made server side using request origin for custom domains
    if (window.location.origin === 'https://tracklive.me' || window.location.origin === 'http://localhost:3000') {
      url = `https://us-central1-gps-tracker-ccad4.cloudfunctions.net/app/status/${window.location.pathname.split('/').filter(s => !!s)[0]}`;
    }
    const res = await axios.get(url)
    return res.data as Account;
  }, {
    onSuccess: data => {
      if (data.latitude && data.longitude) {
        setViewState({
          latitude: data.latitude,
          longitude: data.longitude,
        })  
      }
    }
  });
  if ((window.location.origin === 'https://tracklive.me' || window.location.origin === 'http://localhost:3000') && window.location.pathname === '/') {
    return <Homepage />
  }
  if (req.isLoading) return null;
  return (<>
    {req.data ? <Panel data={req.data} />: null }
    <Map
    zoom={15}
    {...viewState}
    onMove={evt => setViewState(evt.viewState)}
    onZoom={evt => setViewState(evt.viewState)}
    onZoomEnd={evt => setViewState(evt.viewState)}
    onZoomStart={evt => setViewState(evt.viewState)}
    mapboxAccessToken={'pk.eyJ1IjoiZ3BzLXRyYWtpbmctbWlrZWphbWVzIiwiYSI6ImNsNHY1OXY4ajEyeTgzY3FueGNkc3B2a3oifQ.O5YiJply0Ebrtu5FjtVNvw'}
    style={{width: '100%', height: '100vh'}}
    mapStyle="mapbox://styles/gps-traking-mikejames/cluhsmdvh00nc01q5dvjr8lqj"
    // mapbox://styles/gps-traking-mikejames/cl4v5dm2j001m14myz5tivkeb -- basic
  >
    {req.data?.icon === 'boots' ? <Marker longitude={req.data?.longitude} latitude={req.data?.latitude} style={{zIndex:1000, fontSize: '42px'}}>
    🥾
    </Marker> : req.data?.icon === 'car' ? <Marker longitude={req.data?.longitude} latitude={req.data?.latitude} style={{zIndex:1000, fontSize: '42px'}}>
    🚗
    </Marker> : req.data?.icon === 'feet' ? <Marker longitude={req.data?.longitude} latitude={req.data?.latitude} style={{zIndex:1000, fontSize: '42px'}}>
    👣
    </Marker> : req.data?.icon === 'bike' ? <Marker longitude={req.data?.longitude} latitude={req.data?.latitude} style={{zIndex:1000, fontSize: '42px'}}>
    🚲
    </Marker> : req.data?.icon === 'runner' ? <Marker longitude={req.data?.longitude} latitude={req.data?.latitude} style={{zIndex:1000, fontSize: '42px'}}>
    🏃
    </Marker> : req.data?.icon === 'female-runner' ? <Marker longitude={req.data?.longitude} latitude={req.data?.latitude} style={{zIndex:1000, fontSize: '42px'}}>
    🏃‍♀️
    </Marker> : req.data?.icon === 'male-runner' ? <Marker longitude={req.data?.longitude} latitude={req.data?.latitude} style={{zIndex:1000, fontSize: '42px'}}>
    🏃‍♂️
    </Marker> : req.data?.icon === 'dayforit' ? <Marker longitude={req.data?.longitude} latitude={req.data?.latitude} style={{zIndex:1000, fontSize: '42px'}}>
      <img src={require('./pins/dayforit-pin.png')} alt="Day For It" style={{
        width: '80px',
        height: '60px'
      }} />
    </Marker> : <Marker longitude={req.data?.longitude} latitude={req.data?.latitude} style={{zIndex:1000, fontSize: '42px'}}/>}
  </Map>
  </>);
}

function Homepage() {
  const [marker, setMarker] = React.useState<string|undefined>()
  React.useEffect(() => {
    const markers = ['🥾', undefined, '🚗', '👣','🚲'];
    setMarker(markers[Math.floor(Math.random()*markers.length)])
}, [])
  return <div className="lg:fixed left-0 top-0 w-full h-full">
    <div className="lg:absolute left-0 top-0 lg:w-1/2 p-4 text-white bg-gray-900 text-center z-50">
      <div className="tracking-tight text-3xl font-extrabold">tracklive.me</div>
      <div className="text-xs">Let your online following, follow your outdoor adventures.</div>
    </div>
    <div className="lg:absolute top-20 left-3 p-10 z-10 bg-white text-xs lg:w-1/3 space-y-4">
      <div className="pb-3">Upload tracks and share the routes you have taken, great for hiking, cycling and mountain biking</div>
      <div>Taking a few months off and hiking across a continent? Walking for Charity? Walking the coast of the US? Navigating Australia by Bicycle? <a className="bg-blue-600 rounded-md p-3 mt-4 block text-center text-white hover:bg-blue-800" href="https://app.tracklive.me/register">Join tracklive.me</a></div>
      <div className="flex space-x-1 text-xs text-gray-500"></div>
      {window.location.search === '?anton=true' && <>
        <div className="font-bold text-md">
          Happy Customers
        </div>
        <div className="mt-6 flex items-center">
          <div className="flex-shrink-0"> 
            <a href="https://www.thebarefootdutchman.com/home">
              <img className="h-12 w-12 rounded-full" src="/barefootdutchman.png" alt="Barefoot Dutchman"/>
            </a>
          </div>
          <div className="ml-3">
            <p className="text-xs font-medium text-gray-900">
              <a href="https://www.thebarefootdutchman.com/home" className="hover:underline">Anton Nootenboom</a>
            </p>
          <div className="flex space-x-1 text-xs text-gray-500">tracklive.me was with me in every step of the way from Cairns to Sydney, World Record for longest barefoot walk.</div></div></div>
      </>}
    </div>
    <Map
      mapboxAccessToken={'pk.eyJ1IjoiZ3BzLXRyYWtpbmctbWlrZWphbWVzIiwiYSI6ImNsNHY1OXY4ajEyeTgzY3FueGNkc3B2a3oifQ.O5YiJply0Ebrtu5FjtVNvw'}
      style={{width: '100%', height: '100vh'}}
      mapStyle="mapbox://styles/gps-traking-mikejames/cl4v5dm2j001m14myz5tivkeb"    
      initialViewState={{
        longitude:150.3035598,
        latitude:-33.4109589,
        zoom:10
      }}
    >
      {!!marker && <Marker longitude={150.3035598} latitude={-33.4109589} style={{zIndex:1000, fontSize: '42px'}}>
        {marker}
      </Marker>}
      {!marker && <Marker longitude={150.3035598} latitude={-33.4109589} style={{zIndex:1000, fontSize: '42px'}}/>}
    </Map>
</div>
}

type Props = {
  data: Account
}
const Panel: React.FC<Props> = ({data}) => {
  return <div className="fixed lg:block bg-slate-900 bg-opacity-80 rounded-md lg:absolute lg:bottom-auto bottom-0 w-full lg:top-10 lg:left-10 z-20 lg:w-40 lg:h-auto p-4">
    <div className="text-white flex space-y-2 h-full flex-col">
      <h1 className="text-lg tracking-tight font-light text-left">{data.name}</h1>
      <p className="text-xs font-light flex-1">
        {data.description}
      </p>
      <div className="text-xs text-clip space-y-2 flex-col flex">
        {data.instagram && <div className="text-white">
          <a className="hover:underline" href={`https://instagram.com/${data.instagram}`}>📸  Instagram</a>
        </div>}
        {data.youtube && <div className="text-white">
          <a className="hover:underline" href={data.youtube}>📺 Youtube</a>
        </div>}
        {data.charity && <div className="text-white">
          <a className="hover:underline" href={data.charity}>💸 Donate</a>
        </div>}
      </div>

    </div>
  </div>
}

export default App;
